import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Breadcrumb = ({ lang, actualPage, pages }) => (
  <div className="Breadcrumb">
    <div className="container Breadcrumb__container">
      <Link className="Breadcrumb__item" to={`/${lang}/`}>
        Home
      </Link>

      {!!pages &&
        pages.map((elem, index) =>
          !!elem.url ? (
            <Link key={index} className="Breadcrumb__item" to={elem.url}>
              {elem.name}
            </Link>
          ) : (
            <p key={index} className="Breadcrumb__item">
              {elem.name}
            </p>
          )
        )}

      <p className="Breadcrumb__item">{actualPage}</p>
    </div>
  </div>
);

Breadcrumb.propTypes = {
  lang: PropTypes.string.isRequired,
  actualPage: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string, name: PropTypes.string }))
};

export default Breadcrumb;
