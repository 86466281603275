import React from "react";
import PropTypes from "prop-types";
import LinkButton from "./LinkButton";

const BasicSection = ({ children, t, link, image, reverse, smallImage }) => (
  <section className="BasicSection">
    <div
      className={`container BasicSection__container ${reverse ? "BasicSection__container--reverse" : ""} ${
        smallImage ? "BasicSection__container--smallImage" : ""
      }`}
    >
      <div className="BasicSection__mainImage">
        <img src={image} alt={t.alt || t.title} />
      </div>

      <div className="BasicSection__content">
        {!!t.title && <h2 className="BasicSection__title">{t.title}</h2>}
        <p className="BasicSection__description">{t.description}</p>

        {children}

        {!!t.btn && !!link && <LinkButton to={link}>{t.btn}</LinkButton>}
      </div>
    </div>
  </section>
);

BasicSection.propTypes = {
  children: PropTypes.node,
  t: PropTypes.shape({ title: PropTypes.string, description: PropTypes.string.isRequired, button: PropTypes.string }),
  link: PropTypes.string,
  image: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  smallImage: PropTypes.bool
};

export default BasicSection;
