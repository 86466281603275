import React from "react";
import PropTypes from "prop-types";
import Layout from "../../../../layouts/Layout";
import Seo from "../../../seo";
import Intro from "../../../common/Intro";
import Breadcrumb from "../../../common/Breadcrumb";
import BasicSection from "../../../common/BasicSection";
import Advantages from "../../../common/Advantages";
import { getShareImage } from "../../../../utils/getShareImage";

import imgCopywriting from "../../../../images/copywriting.jpg";
import imgCopywritingDesktop from "../../../../images/copywriting-desktop.jpg";
import imgCopywritingBlog from "../../../../images/copywriting-blog.jpg";
import imgCopywritingSocial from "../../../../images/copywriting-social.jpg";
import imgCopywritingNotes from "../../../../images/copywriting-notes.jpg";
import imgExperience from "../../../../images/common/experience.svg";
import imgInnovation from "../../../../images/common/innovation.svg";
import imgLightning from "../../../../images/common/lightning.svg";
import imgHeart from "../../../../images/common/heart.svg";

const Copywriting = props => {
  const { lang, t, layoutT, servicesT, postsT } = props.pageContext;
  const { pathname } = props.location;

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname}>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <Intro title={t.intro.title} />

      <Breadcrumb lang={lang} actualPage={t.seo.title} pages={[{ name: servicesT.marketing.title }]} />

      <div className="Marketing">
        <BasicSection t={t.mainSection} image={imgCopywriting} />

        <BasicSection t={t.landingPageContent} image={imgCopywritingDesktop} reverse />

        <BasicSection t={t.blogArticles} image={imgCopywritingBlog} />

        <BasicSection t={t.socialMediaPosts} image={imgCopywritingSocial} reverse />

        <BasicSection t={t.analysis} image={imgCopywritingNotes} />

        <Advantages t={t.advantages} images={[imgExperience, imgInnovation, imgLightning, imgHeart]} />
      </div>
    </Layout>
  );
};

Copywriting.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Copywriting;
