import React from "react";
import PropTypes from "prop-types";

const Intro = ({ title }) => (
  <div className="Intro">
    <div className="container Intro__container">
      <h1 className="Intro__title text-animate">{title}</h1>
    </div>
  </div>
);

Intro.propTypes = {
  title: PropTypes.string.isRequired
};

export default Intro;
