import React from "react";
import PropTypes from "prop-types";

const Advantages = ({ t, images, columns3, nopadding }) => (
  <section className={`section Advantages ${columns3 ? "Advantages--columns-3" : ""} ${nopadding ? "Advantages--nopadding" : ""}`}>
    <div className="container">
      <h2 className="title">{t.title}</h2>

      <div className="Advantages__list">
        {t.list.map((elem, index) => (
          <div key={index} className="Advantages__item">
            <div className="Advantages__item__image">
              <img src={images[index]} alt={elem.title} />
            </div>
            <h4 className="Advantages__item__title">{elem.title}</h4>
            <p>{elem.description}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

Advantages.propTypes = {
  t: PropTypes.object.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  columns3: PropTypes.bool,
  nopadding: PropTypes.bool
};

export default Advantages;
