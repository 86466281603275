import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const LinkButton = ({ to, white, children }) => (
  <Link to={to} className={`LinkButton ${white ? "LinkButton--white" : ""}`}>
    {children}
  </Link>
);

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  white: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default LinkButton;
